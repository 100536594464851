import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Crud from 'src/components/Crud';
import { Select } from 'src/components/FormHelper/Input';
import Api from 'src/core/api';
import FormController from './FormController';

const FormWrapper = ({ Field, ErrorMessage, setFieldValue, values }) => {
    
    const valuesPermissoes = {
        listar: false,
        novo: false,
        editar: false,
        excluir: false
    }

    const [colaborador, setColaborador] = useState([]);
    const [data, setData] = useState({
        dashboard: {
            listar: false,
            aprovar_cliente: false,
            ver_pedidos: false
        },
        cadastro: {...valuesPermissoes},
        cargos: {...valuesPermissoes},
        cores: {...valuesPermissoes},
        colaborador: {...valuesPermissoes},
        clients: {...valuesPermissoes},
        fornecedores: {...valuesPermissoes},
        products: {...valuesPermissoes},
        'tabela-precos': {...valuesPermissoes},
        'tipo-despesa': {...valuesPermissoes},
        'forma-pagamento': {...valuesPermissoes},
        comissoes: {...valuesPermissoes},
        'status-producao': {...valuesPermissoes},
        
        producao: {...valuesPermissoes},
        'lancar-producao': {...valuesPermissoes},
        'ordens-producoes': {...valuesPermissoes},
        'lancar-produzidos': {...valuesPermissoes},
        'configurar-comissoes': {...valuesPermissoes},
        'estoque-producao': {...valuesPermissoes},
        'estoque-pedido': {...valuesPermissoes},

        vendas: {...valuesPermissoes},
        orders: {...valuesPermissoes},
        pedidos: {...valuesPermissoes},

        financeiro: {...valuesPermissoes},
        'relatorio-comissoes': {...valuesPermissoes},
        'relatorio-producoes': {...valuesPermissoes},
        lancamentos: {...valuesPermissoes},

        relatorios: {...valuesPermissoes},
        'relatorio-pedido': {...valuesPermissoes},
        'relatorio-romaneio': {...valuesPermissoes},
        'relatorio-vendas': {...valuesPermissoes},
        'ultimas-vendas': {...valuesPermissoes},
        'produtos-mais-vendidos': {...valuesPermissoes},
        'relatorio-clientes': {...valuesPermissoes},
        'relatorio-visitas': {...valuesPermissoes},

        autorizacoes: {...valuesPermissoes},
        users: {...valuesPermissoes},
        permissoes: {...valuesPermissoes},
    });

    async function getColaborador() {
        const response = await Api('get', 'users/options', '', {});        
        setColaborador(response.data);
    } 

    useEffect(() => {
        setFieldValue('data', data);
    }, [data]);

    useEffect(() => {
        if(values.data && values.id) {
            if(typeof values.data === 'string') {
            let dataBD = JSON.parse(values.data);
            console.log(data);
            setData({...data, ...dataBD});    
            } else {
            console.log(typeof values.data);
            // setData(values.data);
            setData({...data, ...values.data});    
            }
        }
        getColaborador();
    }, []);

    return (
        <>
            <Row>
                <Col md={6}>
                    <Field 
                        required
                        id="user_id"
                        label="Usuário"
                        name="user_id"
                        placeholder="Selecione"
                        component={Select}
                        list={colaborador}
                        error={<ErrorMessage name="user_id" />}
                    />
                </Col>
            </Row>
            <div style={{ background: '#FFF', padding: 30 }}></div> 
            <FormController data={data} setData={setData} nome="Dashboard" controller="dashboard" />
            <br />
            <FormController data={data} setData={setData} nome="MOD: Cadastros" controller="cadastro" />
            <FormController data={data} setData={setData} nome="Cargos" controller="cargos" />
            <FormController data={data} setData={setData} nome="Cores" controller="cores" />
            <FormController data={data} setData={setData} nome="Colaboradores" controller="colaborador" />
            <FormController data={data} setData={setData} nome="Clientes" controller="clients" />
            <FormController data={data} setData={setData} nome="Fornecedores" controller="fornecedores" />
            <FormController data={data} setData={setData} nome="Produtos" controller="produtos" />
            <FormController data={data} setData={setData} nome="Tabela de Preços" controller="tabela-precos" />
            <FormController data={data} setData={setData} nome="Tipo de Despesas" controller="tipo-despesa" />
            <FormController data={data} setData={setData} nome="Formas de Pagamentos" controller="forma-pagamento" />
            <FormController data={data} setData={setData} nome="Comissões" controller="comissoes" />
            <FormController data={data} setData={setData} nome="Status de Produção" controller="status-producao" />
            <div style={{ background: '#FFF', padding: 30 }}></div> 
            <FormController data={data} setData={setData} nome="MOD: Produção" controller="producao" />
            <FormController data={data} setData={setData} nome="Lançar produção" controller="lancar-producao" />
            <FormController data={data} setData={setData} nome="Ordens de Produção" controller="ordens-producoes" />
            <FormController data={data} setData={setData} nome="Lançar Produzidos" controller="lancar-produzidos" />
            <FormController data={data} setData={setData} nome="Configurador de Comissões" controller="configurar-comissoes" />
            <FormController data={data} setData={setData} nome="Estoque de produção" controller="estoque-producao" />
            <FormController data={data} setData={setData} nome="Estoque de Pedido" controller="estoque-pedido" />
            <FormController data={data} setData={setData} nome="MOD: Vendas" controller="vendas" />
            <FormController data={data} setData={setData} nome="Pedidos" controller="orders" />
            <FormController data={data} setData={setData} nome="Abrir Pedido" controller="pedidos" />
            <div style={{ background: '#FFF', padding: 30 }}></div> 
            <FormController data={data} setData={setData} nome="MOD: Financeiro" controller="financeiro" />
            <FormController data={data} setData={setData} nome="Relatório de Comissões" controller="relatorio-comissoes" />
            <FormController data={data} setData={setData} nome="Relatório de Produções" controller="relatorio-producoes" />
            <FormController data={data} setData={setData} nome="Lançamentos" controller="lancamentos" />
            <div style={{ background: '#FFF', padding: 30 }}></div> 
            <FormController data={data} setData={setData} nome="MOD: Relatórios" controller="relatorios" />
            <FormController data={data} setData={setData} nome="Relatório de Pedidos" controller="relatorio-pedido" />
            <FormController data={data} setData={setData} nome="Relatório de Romaneio" controller="relatorio-romaneio" />
            <FormController data={data} setData={setData} nome="Relatório de Vendas" controller="relatorio-vendas" />
            <FormController data={data} setData={setData} nome="Relatório de Ult. Vendas" controller="ultimas-vendas" />
            <FormController data={data} setData={setData} nome="Relatório Produtos Vendidos" controller="produtos-mais-vendidos" />
            <FormController data={data} setData={setData} nome="Relatório de Clientes" controller="relatorio-clientes" />
            <FormController data={data} setData={setData} nome="Relatório de Visitas" controller="relatorio-visitas" />
            <div style={{ background: '#FFF', padding: 30 }}></div> 
            <FormController data={data} setData={setData} nome="MOD: Autorizações" controller="autorizacoes" />
            <FormController data={data} setData={setData} nome="Usuário" controller="users" />
            <FormController data={data} setData={setData} nome="Permissões" controller="permissoes" />

            <Row>
                
            </Row>
            
           
        </>
    );
}

export default function Permissoes() {
    return (
        <Crud 
            title="Permissões"
            endPoint="permissoes"
            emptyObject={{
                user_id: '',
                data: {}
            }}
            fields={[
                { name: 'id', label: 'Id', classBody: 'min-width' },
                { name: 'user_name', label: 'Nome' }
            ]}
            validation={(yup) => {
                return {
                    
                };
            }}
            FormWrapper={FormWrapper}
        />
    );
}
