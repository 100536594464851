import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Table, Button, Form } from 'react-bootstrap';
import Api from 'src/core/api';
import Helpers from 'src/core/Helpers';
import Pagination from 'src/components/Pagination';
import Message from 'src/core/Message';
import Tooltips from 'src/components/Tooltips';
import HelperContext from 'src/context/helper';
import { GrView } from 'react-icons/gr';
import { BsCardChecklist } from 'react-icons/bs';
import Util from 'src/core/Util';
import useDebounce from 'src/components/useDebounce';
import { AuthorizationMenuListar } from 'src/layouts/Menu';
export default function Home() {

    const { setViewPedido, setViewHistoryPedido, setViewAlterStatus } = useContext(HelperContext);

    const [dataIni, setDataIni] = useState(null);
    const [dataFim, setDataFim] = useState(null);
    const [search, setSearch] = useState('');
    const [params, setParams] = useState({});
    const [periodo, setPeriodo] = useState('');
    const [status, setStatus] = useState('');
    const [page, setPage] = useState(0);
    const [list, setList] = useState([]);
    const [pedidos, setPedidos] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [pagination, setPagination] = useState({});
    const debouncedSearchTerm = useDebounce(params, 500);
    const [totais, setTotais] = useState({
        last_sales_by_day: 0,
        last_sales_by_month: 0,
        last_sales_all: 0,

        total_sales_products: 0,
        last_sales: 0,
        last_geral_sales: 0,
        quant_products: 0,
        total_sales: 0,
        products_more_sales: [],
        products_more_sales_ml: [],
        adverts_more_sales: [],
        adverts_more_sales_ml: [],
        
        total_adverts: 0,
        total_clients: 0,
        total_products: 0, 
    });

    async function approvedClient(id){
        const check = await Message.confirmation("Deseja aprovar o cliente?");
        if(check) {
            await Api('put', `clients/${id}`, '', {
                situacao: 1,
            });
            getClientes();
        }
        
    }

    async function rejectClient(id){
        const check = await Message.confirmation("Deseja rejeitar esse cliente?");
        if(check) {
            await Api('put', `clients/${id}`, '', {
                rejeitado: 1,
            });
            getClientes();
        }
        
    }

    async function approvedPedido(id){
        const check = await Message.confirmation("Deseja aprovar o cliente?");
        if(check) {
            await Api('put', `pedidos/${id}`, '', {
                status: 'approved',
            });
            getPedidos();
        }
        
    }

    async function getTotais() {
        // const response = await Api('get', 'totais', '', {
        //     params: {
        //         data_ini: dataIni,
        //         data_fim: dataFim,
        //         page
        //     }
        // });        
        // setTotais(response.data);
        // setPagination(response.data.adverts_more_sales_ml);
        // setList(response.data.adverts_more_sales_ml.data);
    }

    async function getPedidos() {
        const response = await Api('get', 'pedidos', '', {
            params: {
                data_inicio: dataIni,
                data_fim: dataFim,
                search: search,
                status: status,
                periodo: periodo,
                page,
                ...params
            }
        });        
        setPedidos(response.data);
        setPagination(response);
    }

    async function getClientes() {
        const response = await Api('get', 'clients', '', {
            params: {
                situacao: 0,
                rejeitado: 0,
            }
        });        
        setClientes(response.data);
    }


    useEffect(() => {
        getTotais();
        getPedidos();
        getClientes();
    }, [page]);

    useEffect(() => {
        if(!dataIni && !dataFim) {
            getTotais();
            getPedidos();
        }
    }, [dataIni, dataFim, search, status, periodo]);


    useEffect(() => {
        getPedidos();
    }, [debouncedSearchTerm]);

    return (
        <>
        <AuthorizationMenuListar action="dashboard" btnAction={"listar"}>
            <div className="" role="main">
                <form>  
                    <Row>
                        <Col md={2}>
                            <div className="form-group input-group-sm ">
                                <label>Data Inicial</label>
                                <input value={dataIni} 
                                    onChange={e => setDataIni(e.target.value)} 
                                    className="form-control" type="date" name="data_inicio" />
                            </div>
                        </Col>
                        <Col md={2}>
                            <div className="form-group input-group-sm ">
                                <label>Data Final</label>
                                <input 
                                    value={dataFim}  
                                    onChange={e => setDataFim(e.target.value)} 
                                    className="form-control" 
                                    type="date" 
                                    name="data_fim" />
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="form-group input-group-sm ">
                                <label>Status</label>
                                <select onChange={e => setStatus(e.target.value)} name="status" className="form-control">
                                    <option value="">Selecione o Status</option>
                                    <option value="open">Em Aberto</option>
                                    <option value="waiting">Aguardando Aprovação</option>
                                    <option value="approved">Aprovados</option>
                                    <option value="pending">Pendentes</option>
                                    <option value="production">Em Produção</option>
                                    <option value="travel">Em Viagem</option>
                                    <option value="finished">Finalizado</option>
                                    <option value="cancel">Cancelado</option>
                                </select>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div style={{ marginTop: '27px' }}>
                                <Button variant={periodo === 'today' ? 'warning' : 'info'} size="sm" onClick={() => setPeriodo('today')}>Hoje</Button>
                                <Button variant={periodo === 'yesterday' ? 'warning' : 'info'} size="sm" onClick={() => setPeriodo('yesterday')}>Ontem</Button>
                                <Button variant={periodo === 'month' ? 'warning' : 'info'} size="sm" onClick={() => setPeriodo('month')}>Mês</Button>
                                <Button variant={periodo === 'total' ? 'warning' : 'info'} size="sm" onClick={() => setPeriodo('total')}>Geral</Button>
                                <Button variant="danger" size="sm" onClick={() => setPeriodo('')}>X</Button>
                            </div>
                        </Col>
                        
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label>Código</Form.Label>
                            <Form.Control
                                type="text"
                                id="codigo"
                                name="codigo"
                                placeholder="Código"
                                onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})}
                            />
                        </Col>
                        <Col>
                            <Form.Label>CNPJ</Form.Label>
                            <Form.Control
                                type="text"
                                id="cnpj"
                                name="cnpj"
                                aria-describedby="passwordHelpBlock"
                                placeholder="CNPJ"
                                onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})}
                            />
                        </Col>
                        <Col>
                            <Form.Label>Cidade</Form.Label>
                            <Form.Control
                                type="text"
                                id="cidade"
                                name="cidade"
                                aria-describedby="passwordHelpBlock"
                                placeholder="Cidade"
                                onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})}
                            />
                        </Col>
                        <Col>
                            <Form.Label>Estado</Form.Label>
                            <Form.Control
                                type="text"
                                id="estado"
                                name="estado"
                                aria-describedby="passwordHelpBlock"
                                placeholder="Estado"
                                onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8}>
                            <div className="form-group input-group-sm ">
                                <label>Buscar por Nº Pedido / Cliente / CNPJ / CPF / Endereço / Vendedor / Bairro / Cidade / Estado </label>
                                <input 
                                    value={search}  
                                    onChange={e => setSearch(e.target.value)} 
                                    className="form-control" 
                                    type="text" 
                                    name="search" />
                            </div>
                        </Col>
                        <Col md={2}>
                            <div className="form-group">
                                <br />
                                <button 
                                    onClick={getPedidos} 
                                    type="button" 
                                    style={{ marginTop: '5px' }} 
                                    className="btn-sm btn btn-info">Filtrar</button>
                                &nbsp;
                                <button onClick={() => {
                                    setDataFim('');
                                    setDataIni('');
                                    setPeriodo('');
                                    setStatus('');
                                    setSearch('');
                                }} type="button" style={{ marginTop: '5px' }} 
                                    className="btn btn-warning btn-sm ">Limpar</button>
                            </div>
                        </Col>
                    </Row>
                </form>
                
                <h4>Cliente Aguardando Aprovação</h4>
                {!clientes.filter(item => item.situacao === 0).length && 'Nenhum resultado'}
                
                <Table className={!clientes.filter(item => item.situacao === 0 || item.situacao === '0').length && 'none'}>
                    <tr>
                        <th>Razão Social</th>
                        <th>Fantasia</th>
                        <th>CNPJ</th>
                        <th>Vendedor</th>
                        <th>Ações</th>
                    </tr>
                    {clientes.filter(item => item.situacao === 0 ||  item.situacao === '0').map((item, index) => (
                        <tr>
                            <td>{item.razao_social}</td>
                            <td>{item.nome_fantasia}</td>
                            <td>{item.cnpj}</td>
                            <td>{item.vendedor.name}</td>
                            <td>
                                <AuthorizationMenuListar action="dashboard" btnAction={"editar"}>
                                    <Button size="sm" variant="success" onClick={() => approvedClient(item.id)}>Aprovar</Button>
                                    <Button size="sm" variant="danger" onClick={() => rejectClient(item.id)}>Rejeitar</Button>
                                </AuthorizationMenuListar>
                            </td>
                        </tr>
                    ))}
                </Table>
                <hr />
                

                    <h4>Todos os Pedidos</h4>
                    {!pedidos.length && 'Nenhum resultado'}
                    <Table className={!pedidos.length && 'none'}>
                        <tr>
                            <th>Dados</th>
                            <th>Data de Abertura</th>
                            <th>Valor Total</th>
                        </tr>
                        {pedidos.map((item, index) => (
                            <tr>
                                <td>
                                    <strong>Cód. do Pedido:</strong> {item.codigo} <br />
                                    <strong>Status:</strong> {Util.getStatusSpan(item.status)}
                                    <br />
                                    <strong>Cliente:</strong> {item.cliente.razao_social} <br />
                                    <strong>CNPJ:</strong> {item.cliente.cnpj} <br />
                                    <strong>Endereço:</strong> {item.cliente.logradouro}, {item.cliente.numero} - {item.cliente.bairro} - {item.cliente.cidade} <br />
                                    <strong>Estado/CEP:</strong> {item.cliente.uf} - {item.cliente.cep}<br />
                                    <strong>Vendedor(a):</strong>  {item.vendedor.name} <br />
                                    <span style={{ color: 'red' }}>
                                    {item.desconto_maior ? <>
                                        Foi aplicado um desconto maior que {item.item_tipo_pagamento.desconto}%. O desconto foi de {item.desconto}%.
                                    </> : ''} 
                                </span>
                                </td>
                                <td>
                                <Tooltips text="Visualizar Pedido">
                                    <GrView size="1.5em" onClick={() => setViewPedido(item.id)} />
                                </Tooltips>
                                &nbsp;&nbsp;
                                <Tooltips text="Ver Histórico de Pedido">
                                    <BsCardChecklist size="1.5em" onClick={() => setViewHistoryPedido(item.id)} />
                                </Tooltips>
                                &nbsp;&nbsp;
                                <AuthorizationMenuListar action="dashboard" btnAction={"editar"}>
                                    <Tooltips text="Alterar Status">
                                        <BsCardChecklist size="1.5em" onClick={() => setViewAlterStatus(item.id)} />
                                    </Tooltips>
                                </AuthorizationMenuListar>
                                    <br />
                                    {item.data}
                                </td>
                                <td>
                                    <strong>
                                        {Helpers.converterFloatReal(item.total)}
                                    </strong>
                                </td>
                                <td>
                                    {(item.status === 'waiting' || item.status === 'peinding') && 
                                        <Button size="sm" variant="success" onClick={() => approvedPedido(item.id)}>Aprovar</Button>
                                    }
                                </td>
                            </tr>
                        ))}
                    </Table>
                    <Pagination
                        changePage={data => setPage(data.page)} 
                        data={pagination}                            
                    />
                    <hr />
                
                {/* <div className="row" style={{display: 'inline-block', width: '100%'}} >
                    <div className="tile_count">
                    <div className="col-md-4 col-sm-4  tile_stats_count">
                            <span className="count_top"><i className="fa fa-user"></i> Últimas Vendas</span>
                            <div className="count" >{Helpers.converterFloatReal(totais.last_sales_by_day)}</div>
                            <span className="count_bottom">Hoje</span>
                        </div>
                        <div className="col-md-4 col-sm-4  tile_stats_count">
                            <span className="count_top"><i className="fa fa-user"></i> Últimas Vendas</span>
                            <div className="count" >{Helpers.converterFloatReal(totais.last_sales_by_month)}</div>
                            <span className="count_bottom">Por Mês</span>
                        </div>
                        <div className="col-md-4 col-sm-4  tile_stats_count">
                            <span className="count_top"><i className="fa fa-user"></i> Últimas Vendas</span>
                            <div className="count" >{Helpers.converterFloatReal(totais.last_sales_all)}</div>
                            <span className="count_bottom">Geral</span>
                        </div>
                    </div>
                </div> */}

                {/* <div className="row" style={{display: 'inline-block', width: '100%'}} >
                    <div className="tile_count">
                        <div className="col-md-3 col-sm-4  tile_stats_count">
                            <span className="count_top"><i className="fa fa-clock-o"></i> Qtde. Produtos</span>
                            <div className="count">{totais.total_sales_products || 0}</div>
                            <span className="count_bottom">Vendidos</span>
                        </div>
                        <div className="col-md-3 col-sm-4  tile_stats_count">
                            <span className="count_top"><i className="fa fa-user"></i> Total de Anúncios</span>
                            <div className="count">{totais.total_adverts}</div>
                            <span className="count_bottom">Geral</span>
                        </div>
                        <div className="col-md-3 col-sm-4  tile_stats_count">
                            <span className="count_top"><i className="fa fa-user"></i> Total de Produtos</span>
                            <div className="count">{totais.total_products}</div>
                            <span className="count_bottom">Geral</span>
                        </div>
                        <div className="col-md-3 col-sm-4  tile_stats_count">
                            <span className="count_top"><i className="fa fa-user"></i> Total de Clientes</span>
                            <div className="count">{totais.total_clients}</div>
                            <span className="count_bottom">Geral</span>
                        </div>
                    </div>
                </div> */}

                <hr />
                {/* <Row>
                    <Col md={12}>
                        <h6>Anuncios Mais Vendidos no ML</h6>
                        <Table hover striped>
                            <thead>
                                <tr>
                                    <th>Qtde.</th>
                                    <th>Descrição.</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.length === 0 && 'Nenhum registro...'}
                                {list.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.sold_quantity}</td>
                                        <td>
                                            <a href={item.permalink} rel="noreferrer">{item.title}</a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row> */}
                {/* <Pagination
                    changePage={data => setPage(data.page)} 
                    data={pagination}                            
                /> */}
                {/* <Row>
                    <Col md={6}>
                        <h6>Produtos Mais Vendidos no Sistema</h6>
                        <Table hover striped>
                            <thead>
                                <tr>
                                    <th>Qtde.</th>
                                    <th>Descrição.</th>
                                </tr>
                            </thead>
                            <tbody>
                                {totais.products_more_sales.length === 0 && 'Nenhum registro...'}
                                {totais.products_more_sales.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.total}</td>
                                        <td>
                                            <a href={item.permalink} rel="noreferrer">{item.title}</a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                    <Col md={6}>
                        <h6>Anuncios Mais Vendidos no Sistema</h6>
                        <Table hover striped>
                            <thead>
                                <tr>
                                    <th>Qtde.</th>
                                    <th>Descrição.</th>
                                </tr>
                            </thead>
                            <tbody>
                                {totais.adverts_more_sales.length === 0 && 'Nenhum registro...'}
                                {totais.adverts_more_sales.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.total}</td>
                                        <td>
                                            <a href={item.permalink} rel="noreferrer">{item.title}</a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row> */}
                <hr />
            </div>
        </AuthorizationMenuListar>
        </>
    );
}
