import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Modal, Button } from 'react-bootstrap';
import Crud from 'src/components/Crud';
import Form from 'src/components/Form';
import { InputText, InputTextarea, Select } from 'src/components/FormHelper/Input';
import Api from 'src/core/api';
import Message from 'src/core/Message';
import { AuthorizationMenuListar } from 'src/layouts/Menu';

const FormWrapper = ({ view, values, Field, ErrorMessage }) => {
    // const [listCategories, setListCategories] = useState([]);
    const [listItens, setListItens] = useState([]);
    const [show, setShow] = useState(false);
    const [item, setItem] = useState({
        forma_pagamento_id: null,
        descricao: '',
        dias: null,
        preco_desconto: 0,
        desconto: null
    });
    
    async function getCategories() {
        // const response = await Api('get', 'categories/options', '', {});        
        // setListCategories(response.data);
    }

    async function getListItens() {
        const response = await Api('get', 'itens-tipos-pagamentos', '', {
            params: {
                forma_pagamento_id: values.id
            }
        });        
        setListItens(response.data);
    }

    async function handleItens(e) {
        
        setItem({...item, ...{ [e.target.name]: e.target.value }});
    }

    async function saveItem(values, { setSubmitting }) {
        let dados = {...item, ...{ forma_pagamento_id: values.id }};
        await Api('post', `itens-tipos-pagamentos`, '', values);
        setSubmitting(false);
        setItem({
            forma_pagamento_id: null,
            descricao: '',
            dias: null,
            preco_desconto: 0,
            desconto: null
        });
        getListItens();
        setShow(false);
    }

    async function deleteItem(id) {
        const check = await Message.confirmation("Deseja deletar este registro?");
        if(check) {
            await Api('delete', `itens-tipos-pagamentos/${id}`, '', {});        
            getListItens();
        }
    }

    useEffect(() => {
        if(view === 'edit') {
            getListItens();
        }
        
    }, []);

    return (
        <>
            <Row>
                <Col md={1}>
                    <Field 
                        id="id"
                        label="Código"
                        name="id"
                        component={InputText}
                        disabled
                        error={<ErrorMessage name="id" />}
                    />
                </Col>
                <Col>
                    <Field 
                        required
                        id="name"
                        label="Nome"
                        name="name"
                        component={InputText}
                        error={<ErrorMessage name="name" />}
                    />
                </Col>
            </Row>     
            <Row>
                <Col md={6}>
                    <Field 
                        required
                        id="tipo_pagamento"
                        label="Tipo de Pagamento"
                        name="tipo_pagamento"
                        component={Select}
                        placeholder="Selecione"
                        list={[
                            { label: 'Dinheiro', value: '1' },
                            { label: 'Transferência Ted/Doc', value: '2' },
                            { label: 'Pix', value: '3' },
                            { label: 'Cheque', value: '4' },
                            { label: 'Boleto', value: '5' },
                            { label: 'Cartão', value: '6' },
                        ]}
                        error={<ErrorMessage name="tipo_pagamento" />}
                    />
                </Col>

                {/* <Col md={6}>
                    <Field 
                        required
                        id="desconto"
                        label="Desconto"
                        name="desconto"
                        component={InputText}
                        error={<ErrorMessage name="desconto" />}
                    />
                </Col> */}
            </Row>
            <Row>
                <Col>
                    <AuthorizationMenuListar action="forma-pagamento" btnAction={"editar"}>
                        <Button size="sm" variant="success" onClick={() => setShow(true)}>
                            Novo Item
                        </Button>
                    </AuthorizationMenuListar>

                    
                </Col>
            </Row>
            {view === 'edit' &&
                <Row>
                    <Col md={12}>
                        <h6>Prazos</h6>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Descrição</th>
                                    <th>Dias</th>
                                    <th>Desconto Porcentagem</th>
                                    {/* <th>Desconto Preço</th> */}
                                    <AuthorizationMenuListar action="forma-pagamento" btnAction={"editar"}>
                                        <th>Ações</th>
                                    </AuthorizationMenuListar>
                                </tr>
                            </thead>
                            <tbody>
                                {listItens.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.descricao}</td>
                                        <td>{item.dias}</td>
                                        <td>{item.desconto}</td>
                                        {/* <td>{item.preco_desconto}</td> */}
                                        <AuthorizationMenuListar action="forma-pagamento" btnAction={"editar"}>
                                        <td>
                                            
                                                <a href onClick={() => deleteItem(item.id)}>Excluir</a>
                                            
                                        </td>
                                        </AuthorizationMenuListar>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row> 
            }         
            <Modal show={show} onHide={() => setShow(false)} animation={false}>
                <Modal.Header closeButton>
                <Modal.Title>Novo Item de Pagamento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        emptyObject={{
                            forma_pagamento_id: values.id,
                            descricao: '',
                            dias: '',
                            preco_desconto: 0,
                            desconto: ''
                        }}
                        validation={(yup) => {
                            return {
                                descricao: yup.string().required("Campo obrigatório"),
                                dias: yup.number().required("Campo obrigatório"),
                                // preco_desconto: yup.number().required("Campo obrigatório"),
                                desconto: yup.number().required("Campo obrigatório"),
                            }
                        }}
                        handleSubmit={saveItem}
                        FormWrapper={({ Field, ErrorMessage }) => (
                            <>
                                <Row>
                                    <Col>
                                        <Field 
                                            id="descricao"
                                            name="descricao" 
                                            label="Descrição"
                                            required
                                            component={InputTextarea}
                                            error={<ErrorMessage name="descricao" />}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Field 
                                            id="dias"
                                            name="dias" 
                                            label="Dias"
                                            required
                                            component={InputText}
                                            error={<ErrorMessage name="descricao" />}
                                        />
                                    </Col>
                                    {/* <Col md={6}>
                                        <Field 
                                            id="preco_desconto"
                                            name="preco_desconto" 
                                            label="Preço Desconto"
                                            required
                                            component={InputText}
                                            error={<ErrorMessage name="preco_desconto" />}
                                        />
                                    </Col> */}
                                    <Col md={6}>
                                        <Field 
                                            id="desconto"
                                            name="desconto" 
                                            label="Desconto Porcentagem"
                                            required
                                            component={InputText}
                                            error={<ErrorMessage name="desconto" />}
                                        />
                                    </Col>
                                </Row>
                            </>
                        )}
                    />
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>     
        </>
    );
}

export default function FormaPagamento() {
    return (
        <Crud 
            title="Forma de Pagamento"
            endPoint="forma-pagamento"
            saveContinueForm={true}
            emptyObject={{
                name: '',
                category_id: '',
            }}
            fields={[
                { name: 'id', label: 'Id', classBody: 'min-width' },
                { name: 'name', label: 'Nome' },
                { name: 'tipo_pagamento_f', label: 'Tipo de Pagamento' },
                // { name: 'desconto', label: 'Desconto' }
            ]}
            validation={(yup) => {
                return {
                    name: yup.string().required("Campo obrigatório"),
                    tipo_pagamento: yup.string().required("Campo obrigatório"),
                    // desconto: yup.string().required("Campo obrigatório"),
                };
            }}
            FormWrapper={FormWrapper}
        />
    );
}
