import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Tabs, Tab, Image } from 'react-bootstrap';
import Crud from 'src/components/Crud';
import { Button, FileUpload, InputText, InputTextarea, InputTextFormat, Select } from 'src/components/FormHelper/Input';
import SelectAutoComplete from 'src/components/FormHelper/SelectAutoComplete';
import Api from 'src/core/api';
import Helpers from 'src/core/Helpers';
import Message from 'src/core/Message';
import Dependencias from './Dependencias';
import { AuthorizationMenuListar } from 'src/layouts/Menu';

const FormWrapper = ({ Field, ErrorMessage, values }) => {
    const [listProdutosItens, setListProdutosItens] = useState([]);
    const [image, setImage] = useState(null);
    const [listImages, setListImages] = useState([]);
    const [cores, setCores] = useState([]);
    const [listProducoes, setListProducoes] = useState([]);
    const [listProdutoProducoes, setListProdutoProducoes] = useState([]);
    
    async function getProdutosItens() {
        if(values.id) {
            const response = await Api('get', `produtos-montagem/list-produtos/${values.id}`, '', {});        
            setListProdutosItens(response.data);
        }
    }

    async function getCores() {
        const response = await Api('get', `cores/options`, '', {});        
        setCores(response.data);
    }

    async function getProducoes() {
        const response = await Api('get', `status-producao`, '', {});        
        setListProducoes(response.data);
    }

    async function addProduto() {
        const response = await Api('post', 'produtos-montagem', '', {
            produto_id: values.id,
            produto_montagem_id: values.produto_id,
            quantidade: values.quantidade_produto_item
        });
        Message.success("Item Adicionado com Sucesso.") ;
        getProdutosItens();
        
    }

    async function deleteItem(item) {
        const check = await Message.confirmation("Deseja Remover o Item?");
        if(check) {
            const response = await Api('delete', `produtos-montagem/${item.id}`, '', {});
            Message.success("Item Removido com Sucesso.") ;
            getProdutosItens();
        }
        
    }
    
    async function getImages() {
        const response = await Api('get', `produtos/images/${values.id}`, '', {});
        setListImages(response.data);
    }

    async function addImage() {
        if(image && image.length) {
            let form = new FormData();
            form.append('produto_id', values.id);
            form.append('image', image[0]);
            await Api('post', 'produtos/images', '', form);
            Message.success("Imagem Salva com Sucesso.") ;
            setImage(null);
            getImages();
        } else {
            Message.infor("Selecione uma imagem");
        }
        
    }

    async function deleteImage(item) {
        const check = await Message.confirmation("Deseja Remover a imagem?");
        if(check) {
            await Api('delete', `produtos/images/${item.id}`, '', {});
            Message.success("Imagem Removida com Sucesso.") ;
            getImages();
        }
    }


    async function getProdutosProducoes() {
        const response = await Api('get', `produto-producao-por-produto/${values.id}`, '', {});
        setListProdutoProducoes(response.data);
    }

    
    async function excluirProdutoProducao(id) {
        await Api('delete', `produto-producao/${id}`, '', {});
        getProdutosProducoes();
    }

    async function saveProdutoProducao(e) {
        if(e.target.checked) {
            await Api('post', `produto-producao`, '', {
                produto_id: values.id,
                status_producao_id: e.target.value,
            });
            getProdutosProducoes();
        }
    }

    useEffect(() => {
        getCores();
    }, []);

    useEffect(() => {
        getProdutosItens();
        getImages();
        getProducoes();
        getProdutosProducoes();
    }, [values.id]);

    return (
        <Tabs defaultActiveKey="dados" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="dados" title="Dados">
                <Row>
                    <Col md={1}>
                        <Field 
                            id="id"
                            label="Código"
                            name="id"
                            component={InputText}
                            disabled
                            error={<ErrorMessage name="id" />}
                        />
                    </Col>
                    <Col>
                        <Field 
                            required
                            id="titulo"
                            label="Título"
                            name="titulo"
                            component={InputText}
                            error={<ErrorMessage name="titulo" />}
                        />
                    </Col>
                </Row>   
                <Row>
                    <Col md={3}>
                        <Field 
                            required
                            id="codigo"
                            label="Código de Barras"
                            name="codigo"
                            component={InputText}
                            error={<ErrorMessage name="codigo" />}
                        />
                    </Col>
                    <Col md={3}>
                        <Field
                            id="codigo_geral"
                            label="Código Universal"
                            name="codigo_geral"
                            component={InputText}
                            error={<ErrorMessage name="codigo_geral" />}
                        />
                    </Col>
                    <Col md={3}>
                        <Field 
                            required
                            id="tipo"
                            label="Tipo"
                            name="tipo"
                            component={Select}
                            list={[
                                { value: 'produto', label: 'Produto' },
                                { value: 'semi_produto', label: 'Semi-Produto' },
                                { value: 'materia', label: 'Matéria Prima' },
                            ]}
                            error={<ErrorMessage name="tipo" />}
                        />
                    </Col>
                    <Col md={3}>
                        <Field 
                            id="ncm"
                            label="NCM"
                            name="ncm"
                            component={InputText}
                            error={<ErrorMessage name="ncm" />}
                        />
                    </Col>
                </Row>  
               
                <Row>
                    <Col md={12}>
                        <Field 
                            id="descricao"
                            label="Descrição"
                            name="descricao"
                            component={InputTextarea}
                            error={<ErrorMessage name="descricao" />}
                        />
                    </Col>
                </Row>  
                <Row>
                    <Col md={2}>
                        <Field 
                            required
                            id="valor"
                            label="Valor"
                            name="valor"
                            component={InputTextFormat}
                            error={<ErrorMessage name="valor" />}
                        />
                    </Col>
                    <Col md={2}>
                        <Field 
                            id="custo"
                            label="Custo"
                            name="custo"
                            component={InputTextFormat}
                            error={<ErrorMessage name="custo" />}
                        />
                    </Col>
                    <Col md={2}>
                        <Field 
                            id="peso"
                            label="Peso"
                            name="peso"
                            component={InputText}
                            error={<ErrorMessage name="peso" />}
                        />
                    </Col>
                    <Col md={2}>
                        <Field 
                            required
                            id="desconto"
                            label="Desconto"
                            name="desconto"
                            component={InputText}
                            error={<ErrorMessage name="desconto" />}
                        />
                    </Col>
                    <Col md={2}>
                        <Field 
                            required
                            id="quantidade"
                            label="Quantidade"
                            name="quantidade"
                            component={InputText}
                            error={<ErrorMessage name="quantidade" />}
                        />
                    </Col>
                    <Col md={2}>
                        <Field 
                            required
                            id="tipo_unidade"
                            label="Tipo de Unidade"
                            name="tipo_unidade"
                            component={Select}
                            list={[
                                { value: 'unid', label: 'Unidade' },
                                { value: 'kg', label: 'Kg' },
                            ]}
                            error={<ErrorMessage name="tipo_unidade" />}
                        />
                    </Col>
                </Row> 
                <Row>
                    <Col md={3}>
                        <Field 
                            required
                            id="cor_id"
                            label="Cor"
                            name="cor_id"
                            component={Select}
                            list={cores}
                            error={<ErrorMessage name="cor_id" />}
                        />
                    </Col>
                </Row>
                {/* {JSON.stringify(values, null, 2)} */}
                <Row>
                    <Col md={12}>
                        <label>Fornecedor</label>
                        <Field 
                            required
                            endPoint="fornecedores/options"
                            id="fornecedor_id"
                            label="Selecione o Fornecedor"
                            name="fornecedor_id"
                            component={SelectAutoComplete}
                            error={<ErrorMessage name="fornecedor_id" />}
                        />
                    </Col>
                </Row>
                
                <hr />
                {values.id && 
                    <>
                        <h6>Adicionar Item</h6>
                        <Row>
                            <Col md={10}>
                                <label>Produto</label>
                                <Field 
                                    endPoint="produtos/options"
                                    id="produto_id"
                                    label="Selecione o Produto"
                                    name="produto_id"
                                    component={SelectAutoComplete}
                                    error={<ErrorMessage name="produto_id" />}
                                />
                            </Col>
                            <Col md={2}>
                                <Field 
                                    required
                                    id="quantidade_produto_item"
                                    label="Quantidade"
                                    name="quantidade_produto_item"
                                    component={InputText}
                                    error={<ErrorMessage name="quantidade_produto_item" />}
                                />
                                <AuthorizationMenuListar action="produtos" btnAction={"editar"}>
                                    <Button size="sm" variant="success" onClick={() => addProduto()} name="Adicionar Composto" />
                                </AuthorizationMenuListar>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <h6>Composição dos Items Abaixo</h6>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Título</th>
                                            <th>Tipo Unidade</th>
                                            <th>Quantidade</th>
                                            <AuthorizationMenuListar action="produtos" btnAction={"editar"}>
                                                <th>Ações</th>
                                            </AuthorizationMenuListar>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listProdutosItens.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.produto_montagem.titulo}</td>
                                                <td>{item.produto.tipo_unidade}</td>
                                                <td>{item.quantidade}</td>
                                                <AuthorizationMenuListar action="produtos" btnAction={"editar"}>
                                                    <td>
                                                        
                                                            <a href onClick={() => deleteItem(item)}>Excluir</a>
                                                        
                                                    </td>
                                                </AuthorizationMenuListar>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </>
                }  
            </Tab>
            <Tab eventKey="imagem" title="Imagem" disabled={values.id ? false : true}>
                <Row>
                    {/* {JSON.stringify(image, null, 2)} */}
                    <Col md={10}>
                        <label>Upload de Imagem</label>
                        <Field 
                            type="file"
                            id="image"
                            label="Imagem"
                            name="image"
                            component={FileUpload}
                            handleFile={e => setImage(e.target.files)}
                            error={<ErrorMessage name="image" />}
                        />
                    </Col>
                    <Col md={2}>
                        <Button 
                            style={{ marginTop: '55px' }}
                            onClick={() => addImage()} 
                            variant="warning" 
                            name="Salvar Imagem" 
                        />
                    </Col>
                </Row>
                <br /><br />
                <Row id="list-images">
                    {listImages.map((item, index) => (
                        <Col key={index} md={3}>
                            <Image src={item.path} width="100%" rounded />
                            <Button 
                                variant="danger" 
                                size="sm"
                                onClick={() => deleteImage(item)}
                                name="Deletar"
                                style={{ float: 'right' }}
                            />
                        </Col>
                    ))}
                    <Col>

                    </Col>
                </Row>
            </Tab>
            <Tab eventKey="producao" title="Produção" disabled={values.id ? false : true}>
                <form>
                    {listProducoes.map((item) => (
                        <div style={{ textTransform: 'uppercase' }}>
                            <label htmlFor={item.id}>
                                <input 
                                    id={item.id} 
                                    checked={listProdutoProducoes.filter(i => i.status_producao_id === item.id).length > 0} 
                                    type="checkbox" 
                                    value={item.id}
                                    onChange={e => saveProdutoProducao(e)} 
                                /> {` `} 
                                {item.nome}
                            </label>
                        </div>
                    ))}
                </form>
                <Table hover striped>
                    <thead>
                        <tr>
                            <th>Status</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listProdutoProducoes.map((item, index) => (
                            <tr>
                                <td>{item.status_producao.nome}</td>
                                <td>
                                    <Button variant="warning" size="sm" onClick={() => excluirProdutoProducao(item.id)} name="Excluir" />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Tab>
            <Tab eventKey="dependencia" title="Dependência" disabled={values.id ? false : true}>
                <Dependencias id={values.id} />
            </Tab>
        </Tabs>
    );
}

export default function Produtos() {
    
    return (
        <Crud 
            title="Produtos"
            endPoint="produtos"
            emptyObject={{
                titulo: '',
                valor: '',
                tipo: 'produto',
                quantidade: '',
                produto_id: '',
                fornecedor_id: '',
                cor_id: '',
            }}
            fields={[
                { name: 'id', label: 'Imagem', classBody: 'min-width' },
                { name: 'titulo', label: 'Título' },
                { name: 'valor', label: 'Preço' },
                { name: 'quantidade', label: 'Estoque' },
            ]}
            fieldsHtml={({item}) => (
                <>
                    <td className="min-width">
                        <img src={item.image || ''} width="110px" />
                    </td>
                    <td>{item.titulo}</td>
                    <td className="min-width-10">
                        <strong>
                            {Helpers.converterFloatReal(item.valor)}
                        </strong>
                    </td>
                    <td className="min-width">{item.quantidade || 0}</td>
                </>
            )}
            validation={(yup) => {
                return {
                    titulo: yup.string().required("Campo obrigatório"),
                    valor: yup.string().required("Campo obrigatório"),
                    quantidade: yup.string().required("Campo obrigatório"),
                    tipo_unidade: yup.string().required("Campo obrigatório"),
                    tipo: yup.string().required("Campo obrigatório"),
                    desconto: yup.string().required("Campo obrigatório"),
                };
            }}
            FormWrapper={FormWrapper}
        />
    );
}

