import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Button, Modal } from 'react-bootstrap';
import { AiOutlineFilePdf } from 'react-icons/ai';
import ReportPdf from 'src/components/ReportPdf';
import Api from 'src/core/api';
import Helpers from 'src/core/Helpers';
import Message from 'src/core/Message';
import Pagination from 'src/components/Pagination';
import FilterGlobal from 'src/components/FilterGlobal';
import Util from 'src/core/Util';
import useDebounce from 'src/components/useDebounce';
import { AuthorizationMenuListar } from 'src/layouts/Menu';

export default function LancamentosPedidos() {

    const [page, setPage] = useState(0);
    const [pedidos, setPedidos] = useState([]);
    const [produtos, setProdutos] = useState([]);
        
    const [selected, setSelected] = useState([]);
    const [show, setShow] = useState(false);
    const [showPdf, setShowPdf] = useState(false);
    const [pagination, setPagination] = useState([]);
    const [params, setParams] = useState({});
    const debouncedSearchTerm = useDebounce(params, 500);
    async function getPedidos() {
        const response = await Api('get', 'pedidos', '', {
            params: {
                page,
                ...params
            }
        });        
        setPedidos(response.data);
        setPagination(response);
    }

    async function handleSelected(id) {
        if(selected.includes(id)) {
            let lis = selected.filter(item => item !== id);
            setSelected([...lis]);
        } else {
            setSelected([...selected, id]);
        }
    }

    async function agrupar() {
        if(!selected.length) {
            Message.infor("Selecione um pedido");
            return false;
        }
        
        await Api('post', 'ordem-producao', '', {
            pedidos: selected,
        });
        getPedidos();
        Message.success("Ordem de Pedido Criada com Sucesso.");
        // setShowPdf(true);
    }

    useEffect(() => {
        getPedidos();
    }, [page]);

    useEffect(() => {
        getPedidos();
    }, [debouncedSearchTerm]);

    return (
        <div className="" role="main">
            
            {/* <ReportPdf 
                title="Pedidos Agrupados"
                endPoint="pedidos"
                params={{
                    pedidos: selected,
                    ...params
                }}
                show={showPdf}
                setShow={setShowPdf}
            /> */}
            <h4>Lançamentos de Pedidos para Produção</h4>
            <Row>
                <Col>
                    <AuthorizationMenuListar action="lancar-producao" btnAction={"editar"}>
                        <Button 
                            style={{ float: 'right' }} 
                            size="sm" 
                            variant="success" 
                            onClick={() => agrupar()}>
                                Gerar Ordem de Produção
                        </Button>  
                    </AuthorizationMenuListar>  
                </Col>
            </Row>
            
            <FilterGlobal params={params} setParams={setParams} setSelected={setSelected} list={pedidos}/>
            {!pedidos.length && 'Nenhum resultado'}
            <Table className={!pedidos.length && 'none'}>
                    <tr>
                        <th>Código</th>
                        <th>Status</th>
                        <th>Cliente</th>
                        <th>Data do Pedido</th>
                        <th>Valor Total</th>
                    </tr>
                    {pedidos.map((item, index) => (
                        <tr 
                            onClick={() => handleSelected(item.id)} 
                            className={selected.includes(item.id) ? 'selected' : ''}
                        >
                            <td>{item.codigo}</td>
                            <td>
                                {Util.getStatusSpan(item.status)} <br />
                                {item.ordem_producao_correto_id ? 'Em Ordem de Produção' : ''}
                            </td>
                            <td>
                                <strong>Razão Social: </strong> {item.cliente.razao_social} <br />
                                <strong>Bairro: </strong> {item.cliente.bairro} - <strong>Cidade: </strong> {item.cliente.cidade} - <strong>Estado: </strong> {item.cliente.uf} <br />
                                <strong>Vendedor: </strong>{item.vendedor.name} <br />
                                <strong>Ordem de Produção: </strong>{item.ordem_producao_correto_id} <br />
                            </td>
                            <td>{item.data}</td>
                            <td>
                                {/* {Helpers.converterFloatReal(item.total)} */}
                            </td>
                        </tr>
                    ))}
            </Table>
            <Pagination
                changePage={data => setPage(data.page)} 
                data={pagination}                            
            />
            <hr />
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-70w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title" style={{ width: '100%' }}>
                    <Row>
                        <Col md={10}>
                            <h4>Pedidos</h4>
                        </Col>
                        <Col md={2} style={{ textAlign: 'right' }}>
                            <a href 
                            onClick={() => {
                                setShowPdf(true);
                            }}>
                                <h4>
                                    <AiOutlineFilePdf />
                                </h4>
                            </a>
                        </Col>
                    </Row>
                </Modal.Title>
                </Modal.Header>
                <Modal.Body id="print">
                    <Table style={{ border: '1px solid  #CCC', width: '100%' }}>
                        <tr>
                            <th>Descrição</th>
                            <th  style={{ textAlign: 'right' }}>Quantidade</th>
                            <th style={{ textAlign: 'right' }}>Valor</th>
                        </tr>
                        {produtos.map((item, index) => (
                            <tr>
                                <td>{item.produto.titulo}</td>
                                <td style={{ textAlign: 'right' }}>{item.quantidade}</td>
                                <td style={{ textAlign: 'right' }}>{Helpers.converterFloatReal(item.valor)}</td>
                            </tr>
                        ))}
                        <tr>
                            <th>Total</th>
                            
                            <th colSpan="2" style={{ textAlign: 'right' }}>{Helpers.converterFloatReal(produtos.length > 0 && produtos[0].total)}</th>
                        </tr>
                    </Table>
                </Modal.Body>
            </Modal>
            
            {/* <form>  
                <Row>
                    <Col md={2}>
                        <div className="form-group input-group-sm ">
                            <label>Data Inicial</label>
                            <input value={dataIni} 
                                onChange={e => setDataIni(e.target.value)} 
                                className="form-control" type="date" name="data_ini" />
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className="form-group input-group-sm ">
                            <label>Data Final</label>
                            <input 
                                value={dataFim}  
                                onChange={e => setDataFim(e.target.value)} 
                                className="form-control" 
                                type="date" 
                                name="data_fim" />
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className="form-group">
                            <br />
                            <button 
                                onClick={getTotais} 
                                type="button" 
                                style={{ marginTop: '5px' }} 
                                className="btn-sm btn btn-primary">Filtrar</button>
                            &nbsp;
                            <button onClick={() => {
                                setDataFim('');
                                setDataIni('');
                            }} type="button" style={{ marginTop: '5px' }} 
                                className="btn btn-warning btn-sm ">Limpar</button>
                        </div>
                    </Col>
                    
                </Row>
            </form> */}

            {/* <div className="row" style={{display: 'inline-block', width: '100%'}} >
                <div className="tile_count">
                <div className="col-md-4 col-sm-4  tile_stats_count">
                        <span className="count_top"><i className="fa fa-user"></i> Últimas Vendas</span>
                        <div className="count" >{Helpers.converterFloatReal(totais.last_sales_by_day)}</div>
                        <span className="count_bottom">Hoje</span>
                    </div>
                    <div className="col-md-4 col-sm-4  tile_stats_count">
                        <span className="count_top"><i className="fa fa-user"></i> Últimas Vendas</span>
                        <div className="count" >{Helpers.converterFloatReal(totais.last_sales_by_month)}</div>
                        <span className="count_bottom">Por Mês</span>
                    </div>
                    <div className="col-md-4 col-sm-4  tile_stats_count">
                        <span className="count_top"><i className="fa fa-user"></i> Últimas Vendas</span>
                        <div className="count" >{Helpers.converterFloatReal(totais.last_sales_all)}</div>
                        <span className="count_bottom">Geral</span>
                    </div>
                </div>
            </div> */}

            {/* <div className="row" style={{display: 'inline-block', width: '100%'}} >
                <div className="tile_count">
                    <div className="col-md-3 col-sm-4  tile_stats_count">
                        <span className="count_top"><i className="fa fa-clock-o"></i> Qtde. Produtos</span>
                        <div className="count">{totais.total_sales_products || 0}</div>
                        <span className="count_bottom">Vendidos</span>
                    </div>
                    <div className="col-md-3 col-sm-4  tile_stats_count">
                        <span className="count_top"><i className="fa fa-user"></i> Total de Anúncios</span>
                        <div className="count">{totais.total_adverts}</div>
                        <span className="count_bottom">Geral</span>
                    </div>
                    <div className="col-md-3 col-sm-4  tile_stats_count">
                        <span className="count_top"><i className="fa fa-user"></i> Total de Produtos</span>
                        <div className="count">{totais.total_products}</div>
                        <span className="count_bottom">Geral</span>
                    </div>
                    <div className="col-md-3 col-sm-4  tile_stats_count">
                        <span className="count_top"><i className="fa fa-user"></i> Total de Clientes</span>
                        <div className="count">{totais.total_clients}</div>
                        <span className="count_bottom">Geral</span>
                    </div>
                </div>
            </div> */}

            <hr />
            {/* <Row>
                <Col md={12}>
                    <h6>Anuncios Mais Vendidos no ML</h6>
                    <Table hover striped>
                        <thead>
                            <tr>
                                <th>Qtde.</th>
                                <th>Descrição.</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.length === 0 && 'Nenhum registro...'}
                            {list.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.sold_quantity}</td>
                                    <td>
                                        <a href={item.permalink} rel="noreferrer">{item.title}</a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row> */}
            {/* <Pagination
                changePage={data => setPage(data.page)} 
                data={pagination}                            
            /> */}
            {/* <Row>
                <Col md={6}>
                    <h6>Produtos Mais Vendidos no Sistema</h6>
                    <Table hover striped>
                        <thead>
                            <tr>
                                <th>Qtde.</th>
                                <th>Descrição.</th>
                            </tr>
                        </thead>
                        <tbody>
                            {totais.products_more_sales.length === 0 && 'Nenhum registro...'}
                            {totais.products_more_sales.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.total}</td>
                                    <td>
                                        <a href={item.permalink} rel="noreferrer">{item.title}</a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
                <Col md={6}>
                    <h6>Anuncios Mais Vendidos no Sistema</h6>
                    <Table hover striped>
                        <thead>
                            <tr>
                                <th>Qtde.</th>
                                <th>Descrição.</th>
                            </tr>
                        </thead>
                        <tbody>
                            {totais.adverts_more_sales.length === 0 && 'Nenhum registro...'}
                            {totais.adverts_more_sales.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.total}</td>
                                    <td>
                                        <a href={item.permalink} rel="noreferrer">{item.title}</a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row> */}
            <hr />
        </div>
    );
}

